define("ember-cli-jstree/mixins/ember-jstree-actions", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var testing = Ember.testing;
  exports.default = Ember.Mixin.create({
    callAction: function callAction(actionName) {
      var action = this.get(actionName);

      if (Ember.typeOf(action) === "function") {
        for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        return action.apply(undefined, _toConsumableArray(args));
      }
    },
    _jsTreeFindNodeMatches: function _jsTreeFindNodeMatches(property, values) {
      var treeObject = this.get("treeObject");
      var nodes = [];

      if ("id" === property) {
        // If property is ID, can use get_node, which is faster than search.
        if (Ember.isArray(values)) {
          for (var i = 0; i < values.length; i++) {
            var node = treeObject.jstree(true).get_node(values[i]);
            nodes.push(node);
          }
        }
      } else {
        if (!Ember.isArray(values)) {
          values = Ember.A([values]);
        }

        var data = treeObject.jstree(true)._model.data;
        var dataKeys = Object.keys(data);

        for (var _i = 0; _i < values.length; _i++) {
          var value = values[_i];
          if (!Ember.isNone(value)) {
            for (var j = 0; j < dataKeys.length; j++) {
              var _node = data[dataKeys[j]];
              if (Ember.typeOf(_node.original) !== "undefined" && _node.original[property] === value) {
                nodes.push(_node);
                break;
              }
            }
          }
        }
      }

      return nodes;
    },


    actions: {
      redraw: function redraw() {
        // Redraw true currently does not work as intended. Need to investigate.
        this._refreshTree();
      },
      destroy: function destroy() {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          if (!testing && !this.get("_isDestroying")) {
            treeObject.jstree(true).destroy();
          }

          this.callAction("eventDidDestroy");
        }
      },
      getNode: function getNode(nodeId) {
        if (Ember.typeOf(nodeId) !== "string") {
          throw new Error("getNode() requires a node ID to be passed to it to return the node!");
        }

        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          this.callAction("actionGetNode", treeObject.jstree(true).get_node(nodeId));
        }
      },
      getText: function getText(obj) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          this.callAction("actionGetText", treeObject.jstree(true).get_text(obj));
        }
      },
      getPath: function getPath(obj, glue, ids) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          this.callAction("actionGetPath", treeObject.jstree(true).get_path(obj, glue, ids));
        }
      },
      getChildrenDom: function getChildrenDom(obj) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          this.callAction("actionGetChildrenDom", treeObject.jstree(true).get_children_dom(obj));
        }
      },
      getContainer: function getContainer() {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          this.callAction("actionGetContainer", treeObject.jstree(true).get_container());
        }
      },
      getParent: function getParent(obj) {
        obj = obj || "#";
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          this.callAction("actionGetParent", treeObject.jstree(true).get_parent(obj));
        }
      },
      loadNode: function loadNode(obj, cb) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          this.callAction("actionLoadNode", treeObject.jstree(true).load_node(obj, cb));
        }
      },
      loadAll: function loadAll(obj, cb) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          this.callAction("actionLoadAll", treeObject.jstree(true).load_all(obj, cb));
        }
      },
      openNode: function openNode(obj, cb, animation) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          treeObject.jstree(true).open_node(obj, cb, animation);
        }
      },
      openAll: function openAll(obj, animation) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          treeObject.jstree(true).open_all(obj, animation);
        }
      },
      closeNode: function closeNode(obj, cb) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          treeObject.jstree(true).close_node(obj, cb);
        }
      },
      closeAll: function closeAll(obj, animation) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          treeObject.jstree(true).close_all(obj, animation);
        }
      },
      toggleNode: function toggleNode(obj) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          treeObject.jstree(true).toggle_node(obj);
        }
      },
      createNode: function createNode(obj, node, pos, callback, is_loaded) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          this.callAction("actionCreateNode", treeObject.jstree(true).create_node(obj, node, pos, callback, is_loaded));
        }
      },
      renameNode: function renameNode(obj, val) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          this.callAction("actionRenameNode", treeObject.jstree(true).rename_node(obj, val));
        }
      },
      moveNode: function moveNode(obj, par, pos, callback, is_loaded) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          treeObject.jstree(true).move_node(obj, par, pos, callback, is_loaded);
        }
      },
      copyNode: function copyNode(obj, par, pos, callback, is_loaded) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          treeObject.jstree(true).copy_node(obj, par, pos, callback, is_loaded);
        }
      },
      deleteNode: function deleteNode(obj) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          this.callAction("actionDeleteNode", treeObject.jstree(true).delete_node(obj));
        }
      },
      selectNode: function selectNode(obj, suppress_event) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          treeObject.jstree(true).select_node(obj, suppress_event);
        }
      },
      deselectNode: function deselectNode(obj, suppress_event) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          treeObject.jstree(true).deselect_node(obj, suppress_event);
        }
      },
      selectAll: function selectAll(suppress_event) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          treeObject.jstree(true).select_all(suppress_event);
        }
      },
      deselectAll: function deselectAll(suppress_event) {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          treeObject.jstree(true).deselect_all(suppress_event);
        }
      },
      lastError: function lastError() {
        var treeObject = this.get("treeObject");
        if (!Ember.isNone(treeObject)) {
          var e = treeObject.jstree(true).last_error();
          this.set("_lastError", e);
          this.callAction("actionLastError", e);
        }
      },
      deselectNodes: function deselectNodes(property, values) {
        if (arguments.length === 0) {
          (false && Ember.warn("Using deselectNodes without parameters to deselect all nodes is deprecated. Use the deselectAll action to deselect all nodes."));

          this.send("deselectAll");
          return;
        }

        var treeObject = this.get("treeObject");
        var nodes = this._jsTreeFindNodeMatches(property, values);

        treeObject.jstree(true).deselect_node(nodes, true, true);
        treeObject.jstree(true).redraw(); // Redraw so that parent nodes get their indicator changed.
      },
      selectNodes: function selectNodes(property, values) {
        var treeObject = this.get("treeObject");
        if (null !== treeObject && !this.get("isDestroyed") && !this.get("isDestroying")) {
          var nodes = this._jsTreeFindNodeMatches(property, values);
          treeObject.jstree(true).select_node(nodes, true, true);
        }
      }
    }
  });
});